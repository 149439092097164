import {createSelector} from '@ngrx/store';

import {selectFeature} from '../app.selectors';
import {AuthDataState} from './auth.state';

const selectAuthData = createSelector(selectFeature, ({authData}) => authData);

export const AUTH_DATA_SELECTORS = {
  selectIsAuthenticated: createSelector(
    selectAuthData,
    (authDataState: AuthDataState) => authDataState.isAuthenticated,
  ),

  selectUserData: createSelector(
    selectAuthData,
    (authDataState: AuthDataState) => authDataState.userData,
  ),

  selectToken: createSelector(
    selectAuthData,
    (authDataState: AuthDataState) => authDataState.token,
  ),

  selectTokenExpiration: createSelector(
    selectAuthData,
    (authDataState: AuthDataState) => authDataState.tokenExpiration,
  ),

  selectRefreshToken: createSelector(
    selectAuthData,
    (authDataState: AuthDataState) => authDataState.refreshToken,
  ),

  selectRefreshTokenExpiration: createSelector(
    selectAuthData,
    (authDataState: AuthDataState) => authDataState.refreshTokenExpiration,
  ),

  selectUserRoles: createSelector(
    selectAuthData,
    (authDataState: AuthDataState) => authDataState.userData?.roles,
  ),
};
